import Bannerother from "../../layouts/Welcome/Bannerother"
import Contactcom from "../../layouts/Welcome/Contact"
import Layouts from "../../layouts/Welcome/Layouts"


const Contact = () => {
  return (
    <>
        <Bannerother previous="Accueil" actu="Contact" image="teache (1).svg"  />
        <Contactcom />

    </>
  )
}

export default Contact
export const SET_AUTH_RESET = 'SET_AUTH_RESET'
export const SET_AUTH_IS_LOADING ='SET_AUTH_IS_LOADING'
export const SET_AUTH_IS_AUTH = 'SET_AUTH_IS_AUTH'
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const SET_AUTH_USER = 'SET_AUTH_USER'
export const SET_AUTH_PRICE = 'SET_AUTH_PRICE'
export const SET_AUTH_USER_TYPE = 'SET_AUTH_USER_TYPE'
export const SET_AUTH_MESSAGE_TYPE = 'SET_AUTH_MESSAGE_TYPE'
export const SET_AUTH_MESSAGE = 'SET_AUTH_MESSAGE'
export const SET_AUTH_IS_REGISTER = 'SET_AUTH_IS_REGISTER'
export const SET_AUTH_REGISTER_STEP = 'SET_AUTH_REGISTER_STEP'
import Bannerother from "../../layouts/Welcome/Bannerother"
import Layouts from "../../layouts/Welcome/Layouts"
import Pricingcard from "../../layouts/Welcome/Pricingcard"


const Pricing = () => {
  return (
    <>
        <Bannerother actu="Abonnements" previous="Accueil" image="teache (2).svg" />
        <Pricingcard/>
    </>
  )
}

export default Pricing


import LayoutAdmin from "../../../layouts/Admin/Admin/Layouts";

const HomeAdmin = () => {
    
    
  return (
    <LayoutAdmin actif="exos">
        Admin interface
  </LayoutAdmin>
  )
}

export default HomeAdmin
import Registersecond from "../../layouts/Auth/Registersecond"

const RegisterTwo = () => {
  return (
    <>
    <Registersecond/>
    </>
  )
}

export default RegisterTwo